import {
  createStore
} from 'vuex'
import {
  getSession, setSession
} from '../utils/util.js'
// 1 commit=>mutation  操作mutations
// 2 dispatch=>actions 操作actions
export default createStore({
  state: {
    admin: {}
  },
  mutations: {
    setAdmin(state, admin) {
      state.admin = admin || {}
      setSession('admin', admin)
    },
  },
  getters: {
    admin(state) {
      return state.admin.nick_name ? state.admin : getSession('admin')
    }
  },
  actions: { //执行方法 不可直接对state操作
    setAdmin(ctx, admin) {
      ctx.commit('setAdmin', admin)
    }
  },
  modules: {

  }
})
