import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from "element-plus";
import * as ElementPlusIconVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'

import "./css/waymon.css";

import 'dayjs/locale/zh-cn';
import locale from 'element-plus/es/locale/lang/zh-cn';
const app = createApp(App)

app.use(store)
    .use(router)

app.use(ElementPlus,{locale})
for (const [key,component] of Object.entries(ElementPlusIconVue)) {
    app.component(key,component)
}
app.config.warnHandler = () => null
app.mount('#app')
